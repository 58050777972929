<template>
  <div>
    <v-text-field
      v-model="search"
      label="Tree Search"
      @input="handleSearch"
    />
    <v-treeview
      v-if="$store.state.swApiQuery.apiurl"
      ref="tree"
      v-model="selected"
      selectable
      selected-color="green"
      :items="items"
      :search="search"
      update-all
      return-object
      @input="setOnChangeTree($event)"
    >
      <template
        slot="label"
        slot-scope="props"
      >
        <v-row
          align="center"
        >
          <v-col cols="6">
            {{ props.item.name }}
            <template
              v-if="props.item.isMain"
            >
              ({{ props.item.articleCounter }}/<!-- {{ countArticleLshopSwShop(props.item.children) }}) -->
            </template>
            <template
              v-if="props.item.children && !props.item.isMain"
            >
              ({{ props.item.articleCounter }}/<!-- {{ countSubCategorieArticleLshopSwShop(props.item) }} -->)
            </template>
            <template
              v-if="!props.item.children"
            >
              <v-progress-circular
                v-if="nowImportingItem === props.item.name"
                indeterminate
                color="primary"
              />
            </template>
            <template
              v-for="(option, i) in assignmentCategories"
            >
              <v-icon
                v-if="props.item.KurzArtNr === option.KurzArtNr && option.selectedIds === false"
                :key="i"
                small
                color="red"
                class="mr-2"
                @click="openDialogSwCategories(props.item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-else-if="props.item.KurzArtNr === option.KurzArtNr && option.selectedIds !== ''"
                :key="i"
                small
                color="green"
                class="mr-2"
                @click="openDialogSwCategories(props.item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <v-icon
              v-if="props.item.KurzArtNr"
              small
              color="yellow"
              class="mr-2"
              @click="openDialogSwCategories(props.item)"
            >
              mdi-tooltip-edit
            </v-icon>
            <v-tooltip
              v-if="!props.item.KurzArtNr"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :href="'https://shop.l-shop-team.de/index.php?cl=search&listtype=search&actcontrol=search&Produkt=' + props.item.name.replace(' & ', '+%26+')"
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Atikel Set {{ props.item.name }} in shop.l-shop-team.de anschauen</span>
            </v-tooltip>
            <!-- v-if="compareArticleLshopSwShop(props.item.ArtNr)" -->
            <v-tooltip
              v-if="compareArticleLshopSwShop(props.item.ArtNr)"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :href="'https://' + $store.state.swApiQuery.apiurl + '/search?sSearch=' + props.item.ArtNr"
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    small
                    color="green"
                    class="mr-2"
                  >
                    mdi-marker-check
                  </v-icon>
                </v-btn>
              </template>
              <span>Atikel {{ props.item.ArtNr }} in {{ apiurl }} anschauen</span>
            </v-tooltip>
            <v-tooltip
              v-if="!props.item.children"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :href="'https://shop.l-shop-team.de/index.php?cl=search&searchparam=' + props.item.name"
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Atikel {{ props.item.name }} in shop.l-shop-team.de anschauen</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-treeview>
    <v-dialog
      v-model="dialog"
      max-width="590"
    >
      <v-card>
        <v-card-title class="headline">
          ShopWare Kategorien {{ catCheckbox }}
        </v-card-title>
        <v-card-text>
          <v-list-item
            v-for="(option, i) in swCatItems"
            :key="i"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="catCheckbox"
                  :value="option.id"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ option.pathname }}</v-list-item-title>
                <v-list-item-subtitle>{{ option.name }} - {{ active }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="closeDialogSwCategories"
          >
            Speichern
          </v-btn>
        </v-card-actions>
        <v-divider />
        <p>{{ showArticlesInDialogSwCategories }}</p>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProcess"
      max-width="590"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          class="dialogProcessOpenButton"
          v-bind="attrs"
          v-on="on"
        >
          Process Anzeigen
        </v-btn>
        <v-spacer />
      </template>

      <v-card>
        <v-card-title class="headline">
          Import Process
        </v-card-title>
        <v-card-text>
          <!-- <span v-html="dialogProcessText" /> -->
          <template>
            <v-container fluid>
              <v-textarea
                clearable
                clear-icon="mdi-close-circle"
                label="Text"
                :value="dialogProcessText"
              />
            </v-container>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="dialogProcess = false"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogProcessVariants"
      scrollable
      max-width="590"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          class="dialogProcessVariantsOpenButton"
          v-bind="attrs"
          v-on="on"
        >
          ProcessVariants Anzeigen
        </v-btn>
        <v-spacer />
      </template>

      <v-card>
        <v-card-title class="headline">
          Import Variants Process
        </v-card-title>
        <v-card-text
          id="scrolled-content"
          style="background:#ffffff; height:30000px;"
        >
          <span v-html="dialogProcessVariantsText" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="dialogProcessVariants = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-divider />
    <template>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-text-field
            v-model="preisPlusPercent"
          >
            <template v-slot:label>
              Preis Anpassen in <v-icon style="vertical-align: middle">
                mdi-percent-outline
              </v-icon>
            </template>
          </v-text-field>
          <v-subheader>
            <br>Festgelegte Preisstaffelung:<br>
            0 - 15€ -> * 2.2<br>
            15 - 40€ -> * 2.0<br>
            40 - 80€ -> * 1.9<br>
            80 - ...€ -> * 1.8<br>
            Ergebnis * xy %
          </v-subheader>
        </v-col>
      </v-row>
      <v-spacer />
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <p>
            <br><br>
          </p>
          <v-select
            v-model="imageFactor"
            :items="imageFactorItems"
            :menu-props="{ maxHeight: '400' }"
            label="Bilder Skalierungs Faktor:"
            hint="1 -> Original Bild, 2-5 -> Orig. Höhe und Breite * Faktor"
            persistent-hint
          />
        </v-col>
      </v-row>
      <v-btn
        class="white--text startImportButton"
        color="green darken-1"
        @click="postLshopArticles(); dialogProcessVariants=true"
      >
        Auswahl Import / Update ({{ selectedTreeItemsCounter }})
        <v-icon right>
          mdi-content-save
        </v-icon>
      </v-btn>
      <template>
        {{ countSelectedTreeItems( selected ) }}
      </template>
    </template>
    <template>
      <v-spacer />
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              v-model="oneKurzArtNr"
              label="KurzArtNr"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              v-model="swCatId"
              label="SW Cat ID"
              placeholder="Placeholder"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              v-model="propertyName"
              label="SW propertyName"
              placeholder="Placeholder"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        class="white--text"
        color="green darken-1"
        depressed
        @click="postLshopOneArticle"
      >
        Ein Artikel Importieren
        <v-icon right>
          mdi-content-save
        </v-icon>
      </v-btn>
    </template>

    <template>
      <v-spacer />
      <base-material-card
        icon="mdi-clipboard-text"
        title="Alle Preise abgleichen"
        class="px-5 py-3"
      >
        <p>
          <v-text-field
            v-model="priceUpdatePercent"
          >
            <template v-slot:label>
              Preis Anpassen in <v-icon style="vertical-align: middle">
                mdi-percent-outline
              </v-icon>
            </template>
          </v-text-field>
          <v-subheader>
            <br>Festgelegte Preisstaffelung:<br>
            0 - 15€ -> * 2.2<br>
            15 - 40€ -> * 2.0<br>
            40 - 80€ -> * 1.9<br>
            80 - ...€ -> * 1.8<br>
            Ergebnis * xy %
          </v-subheader>
        </p>
      </base-material-card>
      <v-btn
        class="white--text"
        color="green darken-1"
        depressed
        @click="updateLshopPrices"
      >
        Preise abgleichen
        <v-icon right>
          mdi-content-save
        </v-icon>
      </v-btn>
    </template>
  </div>
</template>
<script>
// /import-lshop/getLshopCategories.php
  // Mixins
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapState } from 'vuex'

  import { globalVars } from '@/globalVars.js'
  export default {

    mixins: [Proxyable],

    data: () => ({
      swApiUrl: globalVars.swApiUrl,
      ebayApiUrl: globalVars.ebayApiUrl,
      ebayBusinesspolicyUrl: globalVars.ebayBusinesspolicyUrl,
      apiurl: '',
      apiuser: '',
      apikey: '',
      sandbox: globalVars.sandbox,
      apiQuery: {},
      // selected: [{ id: 6 }, { id: 8 }],
      search: '',
      dialog: false,
      openedDialogKurzArtNr: '',
      dialogProcess: false,
      dialogProcessText: '',
      dialogProcessVariants: false,
      dialogProcessVariantsText: '',
      assignmentCategories: [],
      showArticlesInDialogSwCategories: [],
      selectedTreeItemsCounter: 0,
      catCheckbox: [],
      swCatItems: [],
      selected: [],
      shopArticles: [],
      preisPlusPercent: 0,
      priceUpdatePercent: 0,
      imageFactor: 1,
      imageFactorItems: [1, 2, 3, 4, 5],
      nowImportingItem: '',
      oneKurzArtNr: '',
      propertyName: '',
      swCatId: '',
      items: [
        {
          id: 1,
          name: 'L-Shop Categorien werden geladen',
          children: [],
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage', 'loginStatus', 'swApiQuery']),
    },

    mounted () {
    // const sets = [
    //   [3, 10, 11, 12, 16],
    //   [1, 3, 18, 19, 21],
    //   [3, 17, 19, 20, 25],
    //   [4, 8, 9, 16, 22],
    //   [1, 3, 5, 11, 16],
    //   [2, 3, 8, 13, 23],
    //   [3, 4, 14, 17, 24],
    //   [3, 5, 7, 8, 10],
    //   [4, 11, 13, 20, 22],
    //   [1, 17, 19, 20, 24],
    // ]
    // setInterval(
    //   function () {
    //     const randomindex = Math.floor(Math.random() * 10)
    //     this.selected = sets[randomindex]
    //     console.log(this.selected)
    //   }.bind(this),
    //   5000,
    // )
    },

    created () {
      // this.selected = [{ id: '42BadeDamen & Test' }, { id: 44 }, { id: 62 }, { id: 63 }]
      // this.getApiData()
      this.getShopCategories()
      this.getLShopCategories()
      this.getAssignmentCategories()
      this.getArticleList()
    },

    methods: {
      getApiData () {
        // const apiurl = this.$cookie.get('apiurl')
        // const apiuser = this.$cookie.get('apiuser')
        // const apikey = this.$cookie.get('apikey')
        // this.apiurl = apiurl
        // this.apiuser = apiuser
        // this.apikey = apikey
        // this.apiQuery = '{ "apiurl": "' + apiurl + '", "apiuser": "' + apiuser + '", "apikey": "' + apikey + '", "sandbox": "' + this.sandbox + '"}'
        // console.log(this.apiQuery)
      },

      handleSearch: function (val) {
        if (val) {
          if (!this.allOpened) {
            this.lastOpen = this.open
            this.allOpened = true
            // this.$refs.tree.updateAll(true)
          }
        } else {
          // this.$refs.tree.updateAll(false)
          this.allOpened = false
          this.open = this.lastOpen
        }
      },

      setOnChangeTree (event) {
        // console.log(event)
        this.showSelected()
        // console.log(this.selected)
      },
      showSelected () {
      // this.selected = [{ id: 6 }, { id: 8 }]
      },

      compareArticleLshopSwShop (ArtNr) {
        if (ArtNr) {
          var filtered = this.shopArticles.filter(d => d.mainDetail.number === ArtNr)
          // console.log('filtered:', filtered)
          if (filtered.length > 0) {
            return true
          } else {
            return false
          }
        }
      },

      countSelectedTreeItems (items) {
        this.selectedTreeItemsCounter = items.length
      },

      countArticleLshopSwShop (item) {
        // console.log('ITM', item)
        var counter = 0
        for (var iArticles = 0; iArticles < item.length; iArticles++) {
          // console.log('COUNTER:', item[iArticles].children)
          for (var i = 0; i < item[iArticles].children.length; i++) {
            var filtered = this.shopArticles.filter(d => d.mainDetail.number === item[iArticles].children[i].ArtNr)
            // console.log('FILT:', filtered)
            if (filtered.length > 0) { counter++ }
          }
        }
        return counter
      },

      countSubCategorieArticleLshopSwShop (item) {
        if (item.children !== undefined) {
          // console.log('ITMx', item.children)
          var counter = 0
          for (var iArticles = 0; iArticles < item.children.length; iArticles++) {
            // console.log('COUNTER:', item.children[iArticles].ArtNr)
            var filtered = this.shopArticles.filter(d => d.mainDetail.number === item.children[iArticles].ArtNr)
            // console.log('FILT:', filtered)
            if (filtered.length > 0) { counter++ }
          }
          return counter
        }
      },

      getArticleList: function () {
        // console.log('1111apiQuery:', this.apiQuery)
        // https://dev.to/ljnce/use-axios-api-with-vue-cli-54i2
        // https://restcountries.eu/rest/v1/all
        this.$http.get(this.swApiUrl + '/sw-api/getArticleList.php').then((resp) => {
          // console.log('22222apiQuery:', this.apiQuery)
          // console.log(JSON.stringify(resp.data.data))
          this.shopArticles = resp.data.data
          // console.log('apiQuery:getArticleList:', JSON.stringify(resp.data))
        })
      },

      openDialogSwCategories (itemValue) {
        this.dialog = true
        this.openedDialogKurzArtNr = itemValue.KurzArtNr
        // console.log('openedDialogKurzArtNr:', this.openedDialogKurzArtNr)
        // console.log('itemValue:', itemValue)
        var checkCats = this.assignmentCategories
        // console.log(checkCats)
        var swCatIds = ''
        for (var iCats = 0; iCats < checkCats.length; iCats++) {
          if (checkCats[iCats].KurzArtNr === this.openedDialogKurzArtNr) {
            // console.log('CHECKCATSX:', checkCats[iCats].KurzArtNr, checkCats[iCats].selectedIds)
            swCatIds = checkCats[iCats].selectedIds
          }
        }
        if (swCatIds) {
          var swCatIdsArray = swCatIds.split(',')
          var forChecked = []
          for (var iswCatIds = 0; iswCatIds < swCatIdsArray.length; iswCatIds++) {
            // console.log('#:', swCatIdsArray[iswCatIds])
            forChecked.push(swCatIdsArray[iswCatIds])
          }
          this.catCheckbox = forChecked
        } else {
          this.catCheckbox = []
        }
        this.showArticlesInDialogSwCategoriesMeth(this.openedDialogKurzArtNr)
      },

      showArticlesInDialogSwCategoriesMeth (openedDialogKurzArtNr) {
        this.showArticlesInDialogSwCategories = []
        var kurzArtNrArray = openedDialogKurzArtNr.split('_')
        var collect = []
        for (var iKurzArtNr = 0; iKurzArtNr < kurzArtNrArray.length; iKurzArtNr++) {
          collect.push(kurzArtNrArray[iKurzArtNr])
        }
        this.showArticlesInDialogSwCategories = collect
      },

      closeDialogSwCategories () {
        this.dialog = false
        // console.log('AtClose:', this.openedDialogKurzArtNr)
        // console.log('catCheckbox:', this.catCheckbox)
        this.saveAssignmentCategories()
      },

      saveAssignmentCategories () {
        this.$http.post(this.swApiUrl + '/import-lshop/postLshopSelectedCategiries.php', JSON.stringify({
          savedSelectedLshopCategories: this.openedDialogKurzArtNr,
          selectedIds: this.catCheckbox,
          apiQuery: this.apiQuery,
        })).then((resp) => {
          // console.log('22222apiQuery:', this.apiQuery)
          // console.log(JSON.stringify(resp.data.data))
          // this.shopArticles = resp.data.data
          console.log('saveAssignmentCategories:', resp.data)
          this.getAssignmentCategories()
        })
      },

      getAssignmentCategories () {
        //  /import-lshop/awazawa.de.SelectedLshopCategories.json
        this.$http.get(this.swApiUrl + '/import-lshop/getSavedLshopCategories.php').then((resp) => {
          // console.log('GET AssignmentCategories: ', JSON.stringify(resp.data))
          this.assignmentCategories = resp.data
        })
      },

      getShopCategories: function () {
        this.$http.get(this.swApiUrl + '/sw-api/getCategories.php').then((resp) => {
          // console.log('getShopCategories: ', JSON.stringify(resp.data.data))
          this.swCatItems = resp.data.data
        })
      },

      getLShopCategories: function () {
        // https://dev.to/ljnce/use-axios-api-with-vue-cli-54i2
        // https://restcountries.eu/rest/v1/all
        this.$http.get(this.swApiUrl + '/import-lshop/getLshopCategories.php?apiQuery=' + this.apiQuery).then((resp) => {
          // console.log('getLShopCategories: ', JSON.stringify(resp.data))
          var allCatData = resp.data
          // var old = JSON.stringify(allCatData).replace(/swCategoriesArrayToReplaceInVue/g, this.swCatItems)
          // convert to JSON string
          // var newArray = JSON.parse(old)
          // convert back to array
          this.items = allCatData
        })
      },

      getFileInfoAddVariants: function () {
        // https://dev.to/ljnce/use-axios-api-with-vue-cli-54i2
        // https://restcountries.eu/rest/v1/all
        this.$http.get(this.swApiUrl + '/import-lshop/' + this.$store.state.swApiQuery.apiurl + '_fileInfoAddVariants.json').then((resp) => {
          // console.log('getLShopCategories: ', JSON.stringify(resp.data))
          // console.log('getFileInfoAddVariants:', resp.data)
          var variantText = ''
          var variantTextHeader
          var variantDoneCounter = 0
          var timeNow = ''

          for (var iVariants = 0; iVariants < resp.data.length; iVariants++) {
            if (resp.data[iVariants].variant) {
              variantText += '<img src="https://js-united.de/lshop-wpai/' + resp.data[iVariants].variant.imageNameOriginal + '" width="30">' +
                ' - ' + resp.data[iVariants].variant.number +
                ' - ' + resp.data[iVariants].variant.size +
                ' - ' + resp.data[iVariants].variant.color + '<br>'
              variantDoneCounter++
              var d = new Date()
              // d.getHours()
              // d.getMinutes()
              // d.getSeconds()
              timeNow = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
            }
            if (resp.data[iVariants].endTime) {
              variantText += '\nBeendet: ' + resp.data[iVariants].endTime + '<br><br>'
            }
            if (resp.data[iVariants].variantCounter) {
              if (resp.data[iVariants].number) {
                variantTextHeader = 'Name: ' + '<a href="https://' + this.$store.state.swApiQuery.apiurl + '/search?sSearch=' + resp.data[iVariants].number + '" target="_blank">' + resp.data[iVariants].articleName + '</a><br>'
              } else {
                variantTextHeader = 'Name: ' + resp.data[iVariants].articleName + '<br>'
              }
              variantTextHeader += 'Varianten ges.: ' + resp.data[iVariants].variantCounter + '/' + variantDoneCounter + '<br>'
              variantTextHeader += 'Getartet: ' + resp.data[iVariants].startTime + '<br>'
              variantTextHeader += 'Vorbereitet: ' + resp.data[iVariants].prepareTime + '<br>'
              variantTextHeader += 'Zeit: ' + timeNow + '<br><br>'
            }
          }
          // var variantText = resp.data.variantCounter
          this.dialogProcessVariantsText = variantTextHeader + variantText
          // var elem = document.getElementById('scrolled-content')
          // setTimeout(
          //   function () {
          //     elem.scroll(0, elem.scrollHeight)
          //   }, 1000)
        })
          .catch((error) => {
            console.log(error)
          })
      },

      postLshopArticles: async function () {
        var checkTree = this.selected
        var checkCats = this.assignmentCategories
        // console.warn(this.assignmentCategories)
        var errorEmptyCategorie = true
        for (var iTree1 = 0; iTree1 < checkTree.length; iTree1++) {
          for (var iCats1 = 0; iCats1 < checkCats.length; iCats1++) {
            // console.log('iTree', iTree1, '-', checkTree[iTree1].KurzArtNr, '<>', checkCats[iCats1].KurzArtNr)
            // var searchString = '12345_RC366_RC3XX_'

            if (checkTree[iTree1].KurzArtNr === checkCats[iCats1].KurzArtNr && checkCats[iCats1].selectedIds !== false) {
              // console.error('IST EIN ARTIKEL')
              var swCatByOneArticle = checkTree[iTree1].KurzArtNr
              var swCatIdByOne = checkCats[iCats1].selectedIds
              errorEmptyCategorie = false
            } else if (checkCats[iCats1].KurzArtNr.search(checkTree[iTree1].KurzArtNr + '_') !== -1 && checkCats[iCats1].selectedIds !== false) {
              // console.warn('IST AUS PARENT', checkTree[iTree1].KurzArtNr)
              var swCatByParent = checkTree[iTree1].KurzArtNr
              var swCatIdByParent = checkCats[iCats1].selectedIds
              errorEmptyCategorie = false
            }
          }
          if (errorEmptyCategorie) console.warn('Bitte mind. eine Kategorie für ' + checkTree[iTree1].KurzArtNr + ' zuweisen!')
          if (swCatByOneArticle) {
            console.log('ONE Import', swCatByOneArticle, ' in', swCatIdByOne)
            this.dialogProcessText = this.dialogProcessText + ' Artikel ' + swCatByOneArticle + ' wird in Kategorien ' + swCatIdByOne + ' importiert'
            this.nowImportingItem = swCatByOneArticle
            // deselect nowImportingItem
            this.selected = this.selected.filter(d => d.KurzArtNr !== this.nowImportingItem)
            console.log('by getFileInfoAddVariants')
            this.getFileInfoAddVariants()
            await this.$http.post(this.swApiUrl + '/import-lshop/postLshopArticles.php',
                                  JSON.stringify({
                                    lshopArticles: { catIDs: swCatIdByOne, filterGroupName: '', id: swCatByOneArticle, preisPlusPercent: this.preisPlusPercent, imageFactor: this.imageFactor },
                                  }),
            )
              .then((resp) => {
                console.log('Server Antwort:', resp.data)
                this.dialogProcessText = this.dialogProcessText + resp.data
                this.nowImportingItem = ''
              }).catch((error) => {
                console.log(error)
              })
            this.getArticleList()
          } else if (swCatIdByParent) {
            console.log('PARENT Import', swCatByParent, ' in', swCatIdByParent)
            this.dialogProcessText = this.dialogProcessText + ' Artikel ' + swCatByParent + ' wird in Kategorien ' + swCatIdByParent + ' importiert'
            this.nowImportingItem = swCatByParent
            // deselect nowImportingItem
            this.selected = this.selected.filter(d => d.KurzArtNr !== this.nowImportingItem)
            console.log('by getFileInfoAddVariants')
            var myVar = setInterval(this.getFileInfoAddVariants, 2000)
            // this.getFileInfoAddVariants()
            await this.$http.post(this.swApiUrl + '/import-lshop/postLshopArticles.php',
                                  JSON.stringify({
                                    lshopArticles: { catIDs: swCatIdByParent, filterGroupName: '', id: swCatByParent, preisPlusPercent: this.preisPlusPercent, imageFactor: this.imageFactor },
                                  }),
            )
              .then((resp) => {
                console.log('Server Antwort:', resp.data)
                this.dialogProcessText = this.dialogProcessText + resp.data
                this.nowImportingItem = ''
                // setTimeout(
                //   function () {
                //     clearInterval(myVar)
                //   }, 3000)
                clearInterval(myVar)
              }).catch((error) => {
                console.log(error)
              })
            this.getArticleList()
          }
          swCatByOneArticle = false
          swCatByParent = false
        }
      },

      postLshopOneArticle: async function () {
        console.log('PostOneArticle')
        console.log(this.oneKurzArtNr, this.propertyName, this.swCatId)
        await this.$http.post(this.swApiUrl + '/import-lshop/postLshopArticles.php',
                              JSON.stringify({
                                lshopArticles: { catIDs: this.swCatId, filterGroupName: this.propertyName, id: this.oneKurzArtNr },
                              }),
        )
          .then((resp) => {
            console.log('Artikel angelegt:', resp.data)
          }).catch((error) => {
            console.log(error)
          })
      },

      updateLshopPrices: async function () {
        console.log('Update Prices')
        console.log(this.priceUpdatePercent, this.propertyName, this.swCatId)
        this.$http.put(this.swApiUrl + '/import-lshop/postLshopArticles.php', JSON.stringify({
          priceUpdatePercent: this.priceUpdatePercent,
          updateLshopPrices: true,
        })).then((resp) => {
          console.log('updateLshopPrices:', resp.data)
        })
      },
    },
  }
</script>

<style scoped>
  .dialogProcessOpenButton{
    position: fixed;
    top: 200px;
    right: -10px;
  }
  .dialogProcessVariantsOpenButton{
    position: fixed;
    top: 250px;
    right: -10px;
  }
  .startImportButton{
    position: fixed;
    top: 300px;
    right: -30px;
  }
</style>
